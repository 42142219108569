.hero {
  padding: 64px 0 24px 0;
}

@screen lg {
  .hero {
    padding: 10% 0 10% 0;
  }
}

.hero-anim {
  animation: 3s hero-anim ease-in-out;
}

@keyframes hero-anim {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
